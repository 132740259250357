
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue';
import Upload from '@/views/LocalRegistration/components/Upload.vue';
import { downloadFromStream } from '@/utils/payment/downloadFile';
import {
    thirdPartyUploadTemplate as thirdPartyUploadTemplateAPI,
    thirdPartyUploadImport as thirdPartyUploadImportAPI,
} from '@/API/localRegistration';

export default defineComponent({
    components: {
        ExclamationCircleOutlined,
        BuSelector,
        Upload,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const columns = [
            {
                title: 'VIN',
                dataIndex: 'vin',
            },
            {
                title: '厂商',
                dataIndex: 'dealer',
            },
            {
                title: '品牌',
                dataIndex: 'brand',
            },
            {
                title: '车系',
                dataIndex: 'typeClass',
            },
            {
                title: '车型',
                dataIndex: 'model',
            },
            {
                title: '排量CC',
                dataIndex: 'displacement',
            },
            {
                title: '省',
                dataIndex: 'province',
            },
            {
                title: '地级市',
                dataIndex: 'city',
            },
            {
                title: '上牌年月',
                dataIndex: 'retailYearMonth',
            },
            {
                title: '上牌日期',
                dataIndex: 'retailTime',
            },
            {
                title: '车身颜色',
                dataIndex: 'color',
            },
            {
                title: '车牌号前2位',
                dataIndex: 'license',
            },
            {
                title: 'Failed Reason',
                slots: { customRender: 'FailedReason' },
            },
        ];

        const tableData = computed(
            () => store.state.localRegistration.thirdPartyDataError
        );

        const visibleUploadDrawer = ref(false);

        const handleToUpload = () => {
            visibleUploadDrawer.value = true;
        };

        const handleDownloadTemplate = async () => {
            const res = await thirdPartyUploadTemplateAPI();
            downloadFromStream(res);
        };

        const uploadBu = ref<string>();

        const handleUpload = async (
            file: File,
            resolve: (value: unknown) => void,
            reject: (reason?: unknown) => void,
            options: any
        ) => {
            try {
                const data = await thirdPartyUploadImportAPI(
                    uploadBu.value as string,
                    file,
                    {
                        onUploadProgress: (progressEvent: any) => {
                            if (progressEvent.lengthComputable) {
                                const percent =
                                    ((progressEvent.loaded /
                                        progressEvent.total) *
                                        100) |
                                    0;
                                options.percent.value = percent;
                            }
                        },
                    }
                );
                resolve(null);
                if (data === '') {
                    message.success('Upload Successfully');
                    router.push({
                        name: 'Local Registration Data Upload Third Party Data',
                    });
                } else {
                    store.commit(
                        'localRegistration/updateThirdPartyDataError',
                        data
                    );
                }
            } catch (e) {
                reject(e);
            }
            visibleUploadDrawer.value = false;
        };

        return {
            columns,
            tableData,
            visibleUploadDrawer,
            handleToUpload,
            uploadBu,
            handleDownloadTemplate,
            handleUpload,
        };
    },
});
